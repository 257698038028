import '../footer.css'

function Footer() {
    return(
        <footer>
            <div class="footy">
                &copy; <a href="https://www.valeriegleason.com" target="_blank">Valerie Gleason</a> 
            </div>
            
        </footer>
    )
}

export default Footer
