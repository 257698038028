function Guidebook() {
    return(
        <div>
            <h1>Guidebook</h1>
            <p class="subtitle">Staying with us? Let us show you around town.</p>
        </div>
    )
}

export default Guidebook
