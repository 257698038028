function Projects () {
    return(
        <div>
            <h1>Projects</h1>
            <p class="subtitle">Projects we've completed, and some future plans.</p>
        </div>
    )
}

export default Projects
