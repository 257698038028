function History() {
    return(
        <div>
            <h1>History</h1>
            <p class="subtitle">We've spent a lot of time learning about this beauty.</p>
        </div>
    )
}

export default History
